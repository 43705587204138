<template>
  <v-container fluid>
    <!---BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumbTabulator.items" />
    <!--EOC-->
    <BreadHeader :title="`New ${model.name.singular}`" />
    <!---BOC:[BreadTabulatorForm]-->
    <BreadTabulatorForm action="add" :api="api" :model="model" />
    <!--EOC-->
    <!---BOC:[note]-->
    <ADevNote
      :text="[
        'Validation',
        ['✗ Validate authority', '✗ Validate ownership'],
        'Layout',
        ['✓ Show breadcrumbs', '✓ Show title', '✓ Replace model item name'],
        'Read',
        ['✓ Run API Read', '✓ Show API Read Loading', '✓ Show API Read Error'],
        'Form',
        [
          '✓ Show Form',
          '✓ Get Form Field',
          '✓ Get Form Rules',
          '✓ Get Form Data',
        ],
        'Action',
        [
          '✓ Form Button isLoading',
          '✓ Run Action API',
          '✓ Show Action API Loading',
          '✓ Show Action API Error',
        ],
        'Parent',
        [
          '✓ Fetch data',
          '✓ Show loading',
          '✗ Show error',
          '✓ Show null',
          '✗ Select default value',
        ],
      ]"
    />
    <!--EOC-->
  </v-container>
</template>

<script>
//BOC:[api]
import Api from "@/objects/api";
//EOC
//BOC:[breadcrumb]
import BreadcrumbTabulator from "@/objects/breadcrumbTabulator";
//EOC
//BOC:[model]
import Model from "@/objects/model";
//EOC
//BOC:[service]
import Service from "@/objects/service";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    services: (state) => state.service.data.services,
  }),
  props: {
    //
  },
  data: () => ({
    //BOC:[api]
    api: new Api(),
    //EOC
    //BOC:[breadcrumbs]
    breadcrumbTabulator: new BreadcrumbTabulator(),
    //EOC
    //BOC:[model]
    model: new Model(),
    //EOC
    //BOC:[service]
    service: new Service(),
    //EOC

    apiStoreUrl: "",

  }),
  created() {
    //BOC:[model]
    this.model.getByKey(this.$route.params.modelKey);
    //EOC
    //BOC:[service]
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    //EOC
    //BOC:[breadcrumbs]
    this.breadcrumbTabulator.addForOperationBrowse({
      model: this.model,
      services: this.services,
    });
    //EOC
    //BOC:[api]
   
    if(this.model.apiUrls.apiStoreUrl != ''){
      this.apiStoreUrl = `${this.$service[this.service.key]}${this.model.apiUrls.apiStoreUrl}`;
    }else{
      this.apiStoreUrl = `${this.$service[this.service.key]}/v1/en/console/tabulator/model/${this.model.key}/add`;
    }

    this.api.setMethod(`POST`);
    this.api.setUrl(this.apiStoreUrl);
    this.api.setCallbackCompleted(() => {
      this.$router.push({
        name: "PageServiceModelBreadTabulatorBrowse",
        params: {
          serviceKey: this.model.serviceKey,
          modelKey: this.model.key,
        },
        query: {
          viewId: 'all',
          tabIndex: 0,
        }
      });
      this.$store.dispatch("showMessage", {
                    message: "Created successfully.",
                    messageType: "success",
                    timeout:2000
                });
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>